<template>
  <BaseCard
    border
    class="mt-2.5 rounded-xl flex flex-col items-center pb-2 h-max bg-white shadow-sm custom-width"
  >
    <div
      class="py-4 px-5 flex gap-x-3 items-center border-b border-neutral-50 border-opacity-80 pr-20 sm:pr-32 w-full"
    >
      <BaseAvatar
        :avatar="publicHeaderInformation.avatar && publicHeaderInformation.avatar"
        size="32"
        class="flex-shrink-0 rounded-full object-fit"
      />
      <div
        class="w-full"
      >
        <BaseText>  {{ publicHeaderInformation.title && publicHeaderInformation.title }} </BaseText>
        <div class="flex">
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal mr-1 w-max whitespace-nowrap"
          >
            Written by
          </BaseText>
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted w-max truncate"
          >
            {{ (publicHeaderInformation.name && publicHeaderInformation.name) || (publicHeaderInformation.email && publicHeaderInformation.email) }}
          </BaseText>
        </div>
      </div>
    </div>
    <div
      class="w-full px-5 flex flex-wrap gap-y-2 sm:gap-y-5 py-4"
    >
      <div
        class="flex items-center h-7 w-full"
      >
        <div
          class="text-text-normal w-24 sm:w-28 mr-2 sm:mr-0 flex items-center "
        >
          <img
            src="../../../assets/icons/loader.svg"
            class="mr-1.5"
          >
          <BaseText
            type="body"
            size="sm"
          >
            Status
          </BaseText>
        </div>
        <BaseBadge
          slot="reference"
          :blue="publicHeaderInformation.status === 'In Progress'"
          :green="publicHeaderInformation.status === 'Completed'"
          :yellow="publicHeaderInformation.status === 'Needs Review'"
          :gray="(publicHeaderInformation.status === 'To Do' || !publicHeaderInformation.status)"
          dot
        >
          <BaseText
            type="label"
            size="xs"
          >
            {{ publicHeaderInformation.status ?? 'To Do' }}
          </BaseText>
        </BaseBadge>
      </div>
      <div
        v-if="publicHeaderInformation.dueDate"
        class="flex items-center h-max w-full"
      >
        <div class="text-text-normal flex w-24 mr-2 sm:mr-0 sm:w-28 mt-1 sm:mt-0 ">
          <img
            src="../../../assets/icons/calendar.svg"
            class="mr-1.5"
          >
          <BaseText
            type="body"
            size="sm"
          >
            Due Date
          </BaseText>
        </div>
        <BaseText
          type="body"
          size="sm"
          class="text-text-muted"
        >
          {{ formattedTime() }}
        </BaseText>
      </div>
      <div
        v-if="publicHeaderInformation.description"
        class="flex h-max w-full flex-col sm:flex-row"
      >
        <div class="text-text-normal w-28 flex sm:justify-center mt-1 sm:mt-0 h-6 mr-5 mb-2 pb-0.5 sm:pb-0">
          <img
            src="../../../assets/icons/pencil.svg"
            class="mr-1.5 h-5 "
          >
          <BaseText
            type="body"
            size="sm"
          >
            Description
          </BaseText>
        </div>
        <BaseText
          type="body"
          size="sm"
          class="text-text-muted wrap w-full sm:w-5/6 h-auto sm:pl-0"
        >
          <editor-content
            :editor="editor"
            class="editor__content"
          />
        </BaseText>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Link from '@tiptap/extension-link'
import Heading from '@tiptap/extension-heading'

export default {
  name: 'PublicHeader',
  components: {
    EditorContent
  },
  props: {
    publicHeaderInformation: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      editor: null,
      remixiconUrl,
      editorValue: null
    }
  },
  mounted () {
    this.editorValue = this.publicHeaderInformation.description || '<p></p>'

    this.editor = new Editor({
      editable: false,
      extensions: [
        StarterKit,
        Link.configure({
          HTMLAttributes: {
            class: 'text-blue-600 cursor-pointer'
          }
        }),
        Heading.configure({
          levels: [1, 2, 3],
          HTMLAttributes: {
            class: 'heading-class'
          }
        })
      ],
      content: this.editorValue
    })
  },
  methods: {
    formattedTime () {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const fullDate = new Date(this.publicHeaderInformation.dueDate)
      const month = months[fullDate.getMonth()]
      const day = fullDate.getDate()
      const year = fullDate.getFullYear()
      return `${month} ${day}, ${year}`
    }
  }
}
</script>

<style scoped>
.custom-width {
  width: 95%;
}

@media (min-width: 640px) {
  .custom-width {
    width: 584px;
  }
}

.editor__content {
  min-height: 1em;
  height: auto;
  overflow: hidden;
}

.editor__content > div {
  height: auto;
}
</style>
